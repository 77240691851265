var getData = require('@/utilities/get/lottieLoader').getData

var _merge = require('lodash/merge')

var getString = require('@/utilities/get/string').getString

module.exports = function() {
  var filters = () => {
    const columns = this.columns

    const list = {}
    for (let a = 0; a < columns.length; a++) {
      const column = columns[a]
      const selected = getString(column, 'selected', '')

      if (Array.isArray(column.searchable) && selected.length >= 1) {
        list[columns[a].field] = selected
        continue
      }

      list[columns[a].field] = ''
    }

    return list
  }

  /*
    condensed
  */
  const condensed = this.condensed
  var headerFontSize = '18px'

  if (condensed) {
    headerFontSize = '16px'
  }

  /*
    height
  */
  const height = this.height

  /*
    header color
  */
  const headerColor = this.headerColor

  /*
    sorting
  */
  const sorted = Object.assign({}, this.sort)

  return {
    lottieOptions: getData(),

    filtersData: filters(),

    mobileSearchField: '',

    paginationData: {
      // current page
      current: 1,

      // total rows
      total: 1
    },

    /*
      [ page ] [ row index ]
    */
    expandedRows: {},

    sorted,

    styles: _merge(
      {},
      {
        tableWrapper: {
          position: 'relative',
          height: typeof height === 'string' && height.length > 0 ? height : 'calc(100vh - 300px)',
          overflowY: 'scroll',
          padding: '0 8px'
        },
        table: {
          width: '100%',
          borderCollapse: 'separate',
          borderSpacing: '0 6px'
        },
        th: {
          background: headerColor,
          boxShadow: `0 -6px 0 0 ${headerColor}`
        },
        headerPanel: {
          background: headerColor
        },
        centerable: {
          display: 'table',
          position: 'relative',
          paddingLeft: '12px',
          paddingRight: '20px',
          verticalAlign: 'middle',
          fontSize: '18px',
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word'
        },
        searchFilter: {
          padding: '0 8px'
        },
        searchFilterBox: {
          height: '40px'
        },
        sortableText: {
          display: 'table-cell',
          fontSize: headerFontSize
        },
        arrows: {
          display: 'table-cell',
          verticalAlign: 'bottom',
          paddingLeft: '5px',
          paddingBottom: '8px'
        },
        mobileArrow: {},
        paginationContainer: {
          margin: '0 auto',
          boxSizing: 'border-box',
          width: '96%',
          padding: '16px 12px 0'
        }
      },
      this._styles
    )
  }
}
