<template>
  <section empty-list class="section" style="width: 100%">
    <div class="content has-text-grey has-text-centered">
      <p v-if="sadFace">
        <b-icon icon="emoticon-sad" size="is-large" />
      </p>
      <p>Nothing Here</p>
    </div>
  </section>
</template>

<script>
/*
  import EmptyList from '@/components/EmptyList'
  
  <EmptyList />
*/

export default {
  props: {
    sadFace: {
      type: Boolean,
      default: true
    }
  }
}
</script>
