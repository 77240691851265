/*
  import ArrayFromTo from '@/utilities/Array/fromTo'

  const result = ArrayFromTo (Array, fromIndex, toIndex)

  Notes:
    Doesn't modify the original array
    Doesn't add indexes that aren't in the array
*/
export default (array, from, to) => {
  let result = []

  try {
    for (let a = from; a <= to; a++) {
      if (a >= array.length) {
        return result
      }
      if (a <= -1) {
        continue
      }

      result.push(array[a])
    }
  } catch (exception) {
    console.error(exception)
  }

  return result
}
