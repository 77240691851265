const _get = require('lodash/get')
//const $ = require('jquery')

module.exports = {
  paginationChange: function(page) {
    this.paginationData.current = page
  },
  sort_: function(column) {
    const field = column.field
    const direction = this.sorted.direction

    this.sorted.numeric = column.numeric || false

    this.sorted.date = column.date || false

    if (this.sorted.by === field) {
      if (direction === 1) {
        this.sorted.direction = 2
      } else {
        this.sorted.direction = 1
      }
    } else {
      this.sorted.by = field
      this.sorted.direction = 2
    }
  },
  switchDirection: function() {
    if (this.sorted.direction === 1) {
      this.sorted.direction = 2
    } else {
      this.sorted.direction = 1
    }
  },
  expandRow: function(index) {
    const currentPage = this.paginationData.current
    if (typeof currentPage !== 'number') {
      console.error(`currentPage is not a number, row cannot be expanded`)
      return
    }

    const key = `${currentPage}.${index}`

    const show = !_get(this, ['expandedRows', key], false)
    this.$set(this.expandedRows, key, show)
  },
  rowOnClick_: function({ row }) {
    const rows = this.rows
    for (let a = 0; a < rows.length; a++) {
      if (rows[a] === row) {
        this.$emit('update:selected', rows[a])

        this.rowOnClick({
          index: a,
          row
        })
      }
    }
  },
  callLoader: function() {
    console.debug('temporarily comment until loader is in component.')
    // setTimeout(() => {
    //   if($('#lottieLoader').length > 0)
    //   {
    //     $('#lottieLoader').each(function( index ) {
    //       $( this ).hide('slow')
    //       console.debug('lottie loader index ' + index)
    //     });
    //   }
    //   else
    //   {
    //     $('#lottieLoader').hide('slow')
    //     this.useLoader = false
    //   }
    // }, 1000)
  }
}
