<template>
  <!--
    The tr attributes need to match the ./Row.vue tr attributes
    for selectability to work correctly
  -->
  <tr
    :style="
      Object.assign({}, styles.row, {
        ...(focusable && { cursor: 'pointer' })
      })
    "
    @click="rowClicked({ row, rowIndex })"
    :selected="selected"
    :class="focusable && selected ? 'row-is-selected' : ''"
  >
    <td colspan="9999">
      <div :style="styles.content">
        <component
          v-if="show && row && row._expandable && row._expandable.component"
          :is="row._expandable.component"
          v-bind="row._expandable.props"
        ></component>
      </div>
      <div
        class="background-panel"
        :style="{
          position: 'absolute',
          top: '-6px',
          width: '100%',
          height: '9px'
        }"
      />
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    row: Object,
    focusable: Boolean,
    selected: Boolean,
    rowIndex: Number,
    onClick: {
      type: Function,
      default: () => {}
    }
  },
  data: function() {
    return {
      show: true,
      styles: {
        content: {
          padding: '0.9em 0.75em'
        }
      }
    }
  },
  watch: {
    row() {
      this.show = false

      this.$nextTick(() => {
        this.show = true
      })
    }
  },
  methods: {
    rowClicked: function(params) {
      this.onClick(params)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_variables';

tr {
  height: 50px;
  width: 100%;

  .background-panel {
    background: $color-2;
  }

  &.row-is-selected {
    td {
      background: $main-color;
      color: $color-2;

      .background-panel {
        background: $main-color;
      }
    }
  }
}

td {
  position: relative;
  top: -12px;

  background: white;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  box-shadow: 0 0 5px 0px #0000002e;
}
</style>
