/*
    //
    // should always return a string
    //
    
    import { getString } from '@/utilities/get/string'

    const str = getString (this, [ 'property' ], '');
 */

import _get from 'lodash/get'

export function getString(a, b, c) {
  try {
    const variable = _get(a, b, c)

    if (typeof variable === 'string') {
      return variable
    }
  } catch (x) {
    console.error(x)
  }

  return ''
}
