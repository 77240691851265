import ArrayFromTo from '@/utilities/Array/fromTo'
import _get from 'lodash/get'
import moment from 'moment'

export default {
  paginationComputed: function() {
    if (this.pagination) {
      const perPage = _get(this, 'pagination.perPage', 10)

      return {
        perPage,

        rangeBefore: 3,
        rangeAfter: 1,
        order: 'is-right',
        size: 'is-small',
        isSimple: false,
        isRounded: false,

        prevIcon: 'chevron-left',
        nextIcon: 'chevron-right'
      }
    }

    return {}
  },

  mobileArrowStyles: function() {
    if (this.sorted.direction === 2) {
      return {
        transition: 'transform .4s',
        transform: 'rotate(180deg)'
      }
    }

    return {
      transition: 'transform .4s',
      transform: 'rotate(0deg)'
    }
  },

  // if column.sortable is boolean true,
  // it is added to the sortable columns list
  sortableColumns: function() {
    return this.columns.filter(column => {
      if (_get(column, 'sortable', false) === true) {
        return true
      }
      return false
    })
  },

  // if column.searchable is boolean true,
  // it is added to the sortable columns list
  searchableColumns: function() {
    return this.columns.filter(column => {
      if (_get(column, 'searchable', false) === true) {
        return true
      }
      return false
    })
  },

  // filters, then sorts
  computedRows: function() {
    var updatedRows = this.rows.slice(0)

    const rowCountChanged = this.paginationData.total !== updatedRows.length

    const sortDirection = this.sorted.direction
    const isNumeric = this.sorted.numeric
    const isDate = this.sorted.date

    /*
      filtering
    */
    const filtersData = this.filtersData
    for (const field in filtersData) {
      const strand = filtersData[field]

      if ((typeof strand === 'string' && strand.length >= 1) || typeof strand === 'boolean') {
        for (let a = 0; a < updatedRows.length; a++) {
          try {
            let entry = updatedRows[a][field]

            // numbers:
            //  parsed into strings for the pupose of filtering
            if (typeof entry === 'number') {
              entry = entry.toString()
            }

            if (typeof entry === 'boolean') {
              if (entry !== strand) {
                updatedRows.splice(a, 1)
                a--
              }
              continue
            }

            // if this column is a date
            // checks for 'entry.date' instead of 'entry'
            if (Object.prototype.hasOwnProperty.call(entry, 'date')) {
              const _entry = entry.date.toUpperCase()
              const _strand = strand.toUpperCase()

              if (_entry.indexOf(_strand) === -1) {
                updatedRows.splice(a, 1)
                a--
              }
            }

            // if strand not found in table entry, remove the row
            else if (typeof entry === 'string') {
              const _entry = entry.toUpperCase()
              const _strand = strand.toUpperCase()

              if (_entry.indexOf(_strand) === -1) {
                updatedRows.splice(a, 1)
                a--
              }
            }
          } catch (exception) {
            console.error(exception)
          }
        }
      }
    }

    /* eslint-disable vue/no-side-effects-in-computed-properties */
    // There's probably no actual risk in updating data within computed props
    // pagination total needs the number of total rows after search filtering,
    // but before pagination filtering
    this.paginationData.total = updatedRows.length
    /* eslint-enable vue/no-side-effects-in-computed-properties */

    const sortedBy = this.sorted.by
    const perPage = _get(this, 'paginationComputed.perPage', 10)
    const currentPage = _get(this, 'paginationData.current', 1)

    const firstIndex = perPage * (currentPage - 1)
    const lastIndex = perPage * currentPage - 1

    /*
      preset expansions
    */
    for (let a = 0; a < updatedRows.length; a++) {
      const row = updatedRows[a]

      const expanded = _get(row, ['_expandable', 'expanded'], false)
      const key = `${currentPage}.${a}`

      if (expanded) {
        this.$set(this.expandedRows, key, true)
      } else {
        // close expanded row if row count changed
        // since expansions are index based
        // and not unique key based
        if (rowCountChanged) {
          this.$set(this.expandedRows, key, false)
        }
      }
    }

    // if not sorted
    if (sortedBy === '') {
      if (this.pagination) {
        return ArrayFromTo(updatedRows, firstIndex, lastIndex)
      } else {
        return updatedRows
      }
    }

    /*
      sorting
    */
    if (isNumeric) {
      updatedRows.sort((a, b) => {
        try {
          var A = parseInt(a[sortedBy])
          var B = parseInt(b[sortedBy])

          if (sortDirection === 1) {
            if (A > B) return -1
            if (B > A) return 1
          }

          if (A > B) return 1
          if (B > A) return -1
        } catch (exception) {
          console.error(exception)
        }

        return 0
      })
    } else if (isDate) {
      updatedRows.sort((a, b) => {
        try {
          var A = moment(a[sortedBy].date, a[sortedBy].format)
          var B = moment(b[sortedBy].date, a[sortedBy].format)

          var isSameOrAfter = A.isSameOrAfter(B)

          if (sortDirection === 1) {
            if (isSameOrAfter) return -1
            if (!isSameOrAfter) return 1
          }

          if (!isSameOrAfter) return -1
          if (isSameOrAfter) return 1
        } catch (exception) {
          console.error(exception)
        }

        return 0
      })
    } else {
      updatedRows.sort((a, b) => {
        try {
          var A = a[sortedBy]
          var B = b[sortedBy]

          if (typeof A === 'number' || typeof A === 'boolean') {
            A = A.toString()
          }
          if (typeof B === 'number' || typeof B === 'boolean') {
            B = B.toString()
          }

          if (typeof A === 'string') {
            A = A.toUpperCase()
          }
          if (typeof B === 'string') {
            B = B.toUpperCase()
          }

          if (A === null) {
            A = ''
          }
          if (B === null) {
            B = ''
          }

          if (sortDirection === 1) {
            if (A > B) return -1
            if (B > A) return 1
          }

          if (A > B) return 1
          if (B > A) return -1
        } catch (exception) {
          console.error(exception)
        }

        return 0
      })
    }

    if (this.pagination) {
      return ArrayFromTo(updatedRows, firstIndex, lastIndex)
    } else {
      return updatedRows
    }
  }
}
