<template>
  <div
    :role="region && region.role"
    :aria-label="region && region.ariaLabel"
    :tests-id="region && region.title && `ModernTable-${region.title}`"
    :style="{ position: 'relative' }"
  >
    <b-loading v-model="loading" :is-full-page="false"> </b-loading>

    <div v-if="$slots.header" :style="{ padding: '0 26px', position: 'relative' }">
      <slot name="header"></slot>
    </div>

    <div class="table-a-wrapper" :style="styles.tableWrapper">
      <!-- mobile sorter -->
      <b-field label="Sort By" class="mobile-sorter">
        <b-select placeholder="" expanded v-model="sorted.by">
          <option v-for="column in sortableColumns" :value="column.field" :key="column.field">
            {{ column.label }}
          </option>
        </b-select>
        <p class="control">
          <b-button type="is-primary" @click="switchDirection">
            <b-icon :style="mobileArrowStyles" pack="mdi" icon="arrow-up" size="is-small"> </b-icon>
          </b-button>
        </p>
      </b-field>

      <!-- mobile search -->
      <b-field v-if="filters && filters.show" label="Filter By" class="mobile-sorter">
        <b-select placeholder="" expanded v-model="mobileSearchField">
          <option
            v-for="column in searchableColumns"
            :aria-label="'filter by ' + (column.aria || column.label)"
            :value="column.field"
            :key="column.field"
          >
            {{ column.label }}
          </option>
        </b-select>
      </b-field>

      <b-field v-if="filters && filters.show" class="mobile-sorter">
        <b-input v-model="filtersData[mobileSearchField]"></b-input>
      </b-field>

      <table :style="styles.table" class="table-a">
        <thead :style="{ zIndex: headerZIndex }">
          <th v-if="expandableRows" :style="Object.assign({}, styles.th, { width: '5%' })">
            <div class="header-panel" :style="styles.headerPanel"></div>
          </th>

          <!-- eslint-disable vue/no-unused-vars -->
          <!-- "key" is necessary for accessing "index" -->
          <th
            v-for="(column, key, index) in columns"
            :key="column.field"
            :style="
              column.width ? Object.assign({}, styles.th, { width: column.width }) : styles.th
            "
          >
            <!-- eslint-enable vue/no-unused-vars -->

            <div class="header-panel" :style="styles.headerPanel"></div>

            <!-- sortable column -->
            <div
              v-if="column.sortable"
              @click="sort_(column)"
              :style="styles.centerable"
              sort
              tabindex="0"
              :aria-label="'sort by ' + (column.aria || column.label)"
            >
              <div :style="styles.sortableText">{{ column.label }}</div>
              <div :style="styles.arrows" class="arrows">
                <div
                  :class="
                    sorted.by === column.field && sorted.direction === 2
                      ? 'highlighted up-arrow'
                      : 'up-arrow'
                  "
                ></div>
                <div
                  :class="
                    sorted.by === column.field && sorted.direction === 1
                      ? 'highlighted down-arrow'
                      : 'down-arrow'
                  "
                ></div>
              </div>
            </div>

            <!-- non-sortable column -->
            <div v-if="!column.sortable" :style="styles.centerable">
              <div :style="styles.sortableText">{{ column.label }}</div>
            </div>

            <!-- search by filter -->
            <b-field
              v-if="
                column.searchable && !Array.isArray(column.searchable) && filters && filters.show
              "
              :style="styles.searchFilter"
            >
              <b-input
                v-model="filtersData[column.field]"
                :aria-label="'filter by ' + (column.aria || column.label)"
                search
              ></b-input>
            </b-field>

            <!-- select box search filter -->
            <b-field
              v-if="
                column.searchable && Array.isArray(column.searchable) && filters && filters.show
              "
              :style="styles.searchFilter"
            >
              <b-select v-model="filtersData[column.field]" search-select>
                <option
                  v-for="option in column.searchable"
                  :key="option.label"
                  :value="option.filter"
                >
                  {{ option.label }}
                </option>
              </b-select>
            </b-field>

            <div
              v-if="!column.searchable && filters && filters.show"
              :style="styles.searchFilterBox"
            ></div>
          </th>
        </thead>

        <!-- <tbody :aria-label="'table body, ' + rows.length + ' rows'" role="region"> -->
        <tbody tabindex="0">
          <!-- <tr v-show="false">
            <td :colspan="columns.length || 1">
              <div id="lottieLoader" v-if="useLoader && useLoader === true">
                <lottie
                  :options="lottieOptions"
                  :height="650"
                  :width="650"
                  style="position: absolute;  margin: auto; top: 0; left: 0; bottom: 0; right: 0; z-index: 1"
                />
              </div>
            </td>
          </tr> -->
          <!-- eslint-disable vue/no-unused-vars -->
          <template v-for="(row, index) in computedRows">
            <!-- eslint-enable vue/no-unused-vars -->

            <!-- eslint-disable vue/valid-v-for -->
            <Row
              :columns="columns"
              :rowObj="row"
              :condensed="condensed"
              :expandableRows="expandableRows"
              :rowIndex="index"
              :onExpand="expandRow"
              :expanded="expandedRows[paginationData.current + '.' + index]"
              :onClick="rowOnClick_"
              :focusable="focusable"
              :selected="selected === row"
              :readMoreColumn="readMoreColumn"
              :htmlColumn="htmlColumn"
            />

            <RowExpandable
              v-if="
                expandedRows[paginationData.current + '.' + index] &&
                  row &&
                  row._expandable &&
                  row._expandable.show
              "
              :row="row"
              :rowIndex="index"
              :onClick="rowOnClick_"
              :focusable="focusable"
              :selected="selected === row"
            />
            <!-- eslint-enable vue/valid-v-for -->
          </template>

          <tr v-if="computedRows && computedRows.length === 0 && !loading">
            <td :colspan="columns.length || 1"><EmptyList /></td>
          </tr>
        </tbody>
      </table>
    </div>

    <div :style="styles.paginationContainer" class="table-a-pagination-container" v-if="pagination">
      <b-pagination
        v-model="paginationData.current"
        @change="paginationChange"
        :total="paginationData.total"
        :range-before="paginationComputed.rangeBefore"
        :range-after="paginationComputed.rangeAfter"
        :order="paginationComputed.order"
        :size="paginationComputed.size"
        :simple="paginationComputed.isSimple"
        :rounded="paginationComputed.isRounded"
        :per-page="paginationComputed.perPage"
        :icon-prev="paginationComputed.prevIcon"
        :icon-next="paginationComputed.nextIcon"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
      >
      </b-pagination>
    </div>
  </div>
</template>

<script>
/*
  import ModernTable from '@/components/tables/Modern/Table'

  <ModernTable
    :columns="[
      {
        field: 'lotNumber',
        label: 'Lot #',
        numeric: true,
        sortable: true,
        searchable: true
      },
      {
        field: 'name',
        label: 'Name',
        sortable: true,
        searchable: true
      },
      {
        field: 'address',
        label: 'Address',
        sortable: true,
        searchable: true
      },
      {
        field: 'status',
        label: 'Status'
      }
    ]"
    :rows="[{
      // add as "data-id=1" to the row
      _dataId: "1",
      
      // ? meta information that can be retrieved?
      _meta: {},
      
    }]"
    :filters="{
      show: true
    }"
  />
*/

import EmptyList from '@/components/EmptyList'
import Row from './components/Row'
import RowExpandable from './components/RowExpandable'
//import Lottie from 'vue-lottie'
import { watch } from './keys/watch'

export default {
  components: {
    EmptyList,
    Row,
    RowExpandable //,
    //Lottie
  },

  mounted() {
    this.callLoader()
  },

  watch,

  props: require('./keys/props'),

  /* eslint-disable vue/no-shared-component-data */
  data: require('./keys/data'),
  /* eslint-enable vue/no-shared-component-data */

  computed: require('./keys/computed').default,

  methods: require('./keys/methods')
}
</script>

<style lang="scss">
.table-a-pagination-container {
  .pagination {
    .pagination-list {
      order: 1 !important;
    }
    .pagination-previous {
      order: 2 !important;
    }
    .pagination-next {
      order: 3 !important;
    }
  }
}
</style>

<style lang="scss" scoped>
@import '~@/assets/styles/_variables';

.mobile-label,
.mobile-sorter {
  display: none;
}

@media screen and (max-width: $desktop) {
  .mobile-sorter {
    display: block;

    .button .icon {
      transition: transform 1s;

      &.up {
        transform: rotate(0deg);
      }

      &.down {
        transform: rotate(180deg);
      }
    }
  }

  thead {
    display: none;
  }
}

.table-a {
  .arrows {
    .up-arrow {
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 4px solid #00000029;

      margin-bottom: 2px;

      &.highlighted {
        border-bottom: 5px solid purple;
      }
    }

    .down-arrow {
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid #00000029;

      &.highlighted {
        border-top: 5px solid purple;
      }
    }
  }
}

a {
  border-radius: 6px;
}

thead {
  position: relative;
  z-index: 3;
  border-spacing: 0 0;

  th {
    position: sticky;
    top: 6px;
    min-width: 50px;
    padding: 2px 0;
    vertical-align: bottom;
    user-select: none;
    cursor: pointer;

    color: #838383;
  }

  th:first-child,
  th:last-child {
    .header-panel {
      display: table;
      position: absolute;
      top: -6px;

      width: 100%;
      height: calc(100% + 6px);

      border-radius: 4px;
    }
  }

  th:first-child {
    .header-panel {
      padding-left: 20px;
      left: -8px;
    }
  }

  th:last-child {
    .header-panel {
      padding-right: 20px;
      right: -8px;
    }
  }
}
</style>
