var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{class:_vm.focusable && _vm.selected ? 'row-is-selected' : '',style:(Object.assign({}, _vm.styles.row, Object.assign({}, (_vm.focusable && { cursor: 'pointer' })))),attrs:{"modern-tr":"","data-id":_vm.dataId,"selected":_vm.selected},on:{"click":function($event){return _vm.rowClicked({ row: _vm.row, rowIndex: _vm.rowIndex })}}},_vm._l((_vm.row),function(entry,key,index){return (key !== '_meta')?_c('td',{style:(_vm.styles.td),attrs:{"i":index}},[(_vm.expandableRows && key !== '_expandable')?_c('div',{staticClass:"mobile-label"},[_vm._v(" "+_vm._s(_vm.columns && _vm.columns[index - 1] && _vm.columns[index - 1].label)+" ")]):(key !== '_expandable')?_c('div',{staticClass:"mobile-label"},[_vm._v(" "+_vm._s(_vm.columns && _vm.columns[index] && _vm.columns[index].label)+" ")]):_vm._e(),(
        key === '_expandable' &&
          entry &&
          entry.show &&
          (entry.showIcon || entry.showIcon == undefined)
      )?_c('div',{staticClass:"expandable-icon-container",style:({
        marginLeft: '-6px',
        marginTop: '4px'
      }),attrs:{"expandable-icon":""},on:{"click":function($event){return _vm.onExpand(_vm.rowIndex)}}},[_c('b-icon',{staticClass:"expandable-arrow-icon",style:(_vm.styles.icon),attrs:{"pack":"mdi","icon":"chevron-right","custom-size":"mdi-24px","type":"is-primary"}})],1):_vm._e(),(_vm.expandableRows && key === '_expandable')?_c('div',{staticClass:"mobile-label"}):(key === '_expandable' && entry && entry.show === false)?_c('div',{attrs:{"no-expandable-icon":""}}):(entry && entry.component)?_c(entry.component,_vm._b({tag:"component"},'component',entry.props,false)):(entry && entry.date)?_c('span',{attrs:{"t":""}},[_vm._v(_vm._s(entry.date))]):(
        (typeof entry === 'string' || typeof entry === 'number' || typeof entry === 'boolean') &&
          key !== '_expandable' &&
          (!_vm.readMoreColumn || _vm.readMoreColumn !== _vm.columns[index].label)
      )?_c('span',{attrs:{"t":""}},[_vm._v(" "+_vm._s(_vm.columns[index] && (typeof _vm.columns[index].prefix === 'string' ? _vm.columns[index].prefix : null))),(_vm.htmlColumn.length > 0 && _vm.htmlColumn === _vm.columns[index].label)?_c('span',{domProps:{"innerHTML":_vm._s(entry)}}):_c('span',[_vm._v(_vm._s(entry))])]):(
        (typeof entry === 'string' || typeof entry === 'number' || typeof entry === 'boolean') &&
          key !== '_expandable' &&
          _vm.readMoreColumn &&
          _vm.readMoreColumn === _vm.columns[index].label
      )?_c('span',{attrs:{"t":""}},[_vm._v(_vm._s(_vm.columns[index] && (typeof _vm.columns[index].prefix === 'string' ? _vm.columns[index].prefix : null))+" "),_c('div',{staticClass:"content"},[_c('p',[_vm._v(" "+_vm._s(entry.length > 300 ? entry.substring(0, 300) : entry)+" ")])]),(entry.length > 300)?_c('b-collapse',{attrs:{"open":false,"position":"is-bottom","aria-id":"contentIdForA11y4"},scopedSlots:_vm._u([{key:"trigger",fn:function(props){return [_c('a',{attrs:{"aria-controls":"contentIdForA11y4","aria-expanded":props.open}},[_c('b-icon',{attrs:{"icon":!props.open ? 'menu-down' : 'menu-up'}}),_vm._v(" "+_vm._s(!props.open ? 'Read More...' : 'Read Less')+" ")],1)]}}],null,true)},[_c('p',[_vm._v(" "+_vm._s(entry.length > 300 ? entry.substring(300, entry.length) : entry)+" ")])]):_vm._e()],1):_vm._e()],1):_vm._e()}),0)}
var staticRenderFns = []

export { render, staticRenderFns }