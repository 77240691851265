module.exports = {
  _styles: {
    type: Object,
    default: () => {
      return {}
    }
  },
  columns: {
    type: Array,
    default: () => {
      return []
    }
  },
  readMoreColumn: {
    type: String,
    default: ''
  },
  htmlColumn: {
    type: String,
    default: ''
  },
  condensed: {
    type: Boolean,
    default: false
  },
  expandableRows: {
    type: Boolean,
    default: false
  },
  headerColor: {
    type: String,
    default: '#fafafa'
  },
  height: {
    type: String,
    default: ''
  },
  focusable: {
    type: Boolean,
    default: false
  },
  useLoader: {
    type: Boolean,
    default: true
  },
  filters: {
    type: Object,
    default: () => {
      return {
        show: false
      }
    }
  },
  headerZIndex: {
    type: Number,
    default: 3
  },
  loading: {
    type: Boolean,
    default: false
  },
  visible: {
    type: Boolean,
    default: true
  },
  onUpdate: {
    type: Function,
    default: () => {}
  },
  pagination: {
    type: [Object, Boolean],
    default: false
  },
  region: {
    type: Object,
    default: () => {
      return {
        role: 'main'
      }
    }
  },
  rowOnClick: {
    type: Function,
    default() {}
  },
  rows: {
    type: Array,
    default: () => {
      return []
    }
  },
  selected: {
    type: Object,
    default: () => {}
  },
  sort: {
    type: Object,
    default: () => {
      return {
        by: '',

        // 1 -> forward
        // 2 -> reversed
        direction: 1,

        numeric: false,
        date: false
      }
    }
  }
}
